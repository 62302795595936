// Primary Colors ///////////////////////////////////
// 1) Bunting
export const darkBlue = "#19234F"
// 2) Cloud Burst
export const violet = "#26305C"
// 3) Cloud Burst #2
export const darkViolet = "#1F274A"

// Secondary Colors /////////////////////////////////
// 1) Sapphire
export const sapphire = "#32459C"
// 2) Ebony
export const ebony = "#0D1228"
// 3) Mine Shaft
export const offBlack = "#222222"
// 4) White
export const white = "#FFFFFF"

// Complimentary Colors /////////////////////////////
export const lavender = "#E4CCF6"
export const periwinkle = "#4168B2"
export const purple = "#3D046A"